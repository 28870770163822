// ChatMessage.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import TextToSpeechButton from "./TextToSpeechButton";
import Cookies from "js-cookie";
import { getChatID } from "./chatIDModule";
import { Tooltip } from "react-tooltip";
import ChatBoxLoader from "./ChatBoxLoader";
import { translations } from "../translations";

const ChatMessage = ({
  message,
  isLastMessage,
  lastUserMessage,
  audioOn,
  selectLanguage,
  onDataFromChild,
  handleIsWriting,
  loader,
}) => {
  const [userAgreed, setUserAgreed] = useState(false);
  const [userDisagreed, setUserDisagreed] = useState(false);

  const handleAgree = async () => {
    setUserAgreed(true);
    setUserDisagreed(false);

    sendFeedback(lastUserMessage.text, 1);
  };

  const handleDisagree = async () => {
    setUserDisagreed(true);
    setUserAgreed(false);

    sendFeedback(lastUserMessage.text, 0);
  };
  const sendFeedback = async (text, binaire) => {
    try {
      await axios.post("https://junkapi.com", {
        chat_response: text,
        feedback: binaire,
        ChatID: "TempFromCBUI",
        auth_key: "q8olbmjjOmONSx'Qvu04rFjNKRP^%CRr+ELaluZnZEnH%]ca+w",
      });
    } catch (error) {
      console.error("Erreur lors de la récupération du feedback :", error);
      // Gérer les erreurs d'API
    }
  };
  const [agreeButtonText, setAgreeButtonText] = useState("I Agree");
  const [disagreeButtonText, setDisagreeButtonText] = useState("I Disagree");

  useEffect(() => {
    // Mettez en place une logique pour changer le texte des boutons en fonction de la langue sélectionnée
    switch (selectLanguage) {
      case "en":
        setAgreeButtonText("I Agree");
        setDisagreeButtonText("I Disagree");

        break;
      case "de":
        setAgreeButtonText("Ich stimme zu");
        setDisagreeButtonText("Ich stimme nicht zu");

        break;
      case "nl":
        setAgreeButtonText("Ik ga akkoord");
        setDisagreeButtonText("Ik ga niet akkoord");

        break;
      case "fr":
        setAgreeButtonText("Je suis d'accord");
        setDisagreeButtonText("Je ne suis pas d'accord");

        break;
      default:
        setAgreeButtonText("Je suis d'accord"); // La langue par défaut
        setDisagreeButtonText("Je ne suis pas d'accord"); // La langue par défaut
    }
  }, [selectLanguage]);

  const [data, setData] = useState("");
  const [loading, setLoading] = useState(loader);
  const [streamingComplete, setStreamingComplete] = useState(false);

  useEffect(() => {
    const fetchData = async (response) => {
      try {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { value, done } = await reader.read();

          if (done) {
            break;
          }

          const decodedChunk = decoder.decode(value, { stream: true });
          if (
            message.isSuggestion &&
            lastUserMessage.text !== "Dis m'en plus..." &&
            lastUserMessage.text !== "Tell me more..." &&
            lastUserMessage.text !== "Vertel me meer..." &&
            lastUserMessage.text !== "Erzähl mir mehr..."
          ) {
            try {
              handleIsWriting(true);
              // Supprimez les guillemets ("") de la réponse
              const suggestionContent = JSON.parse(
                decodedChunk
              ).AI_response.replace(/"/g, "");

              // Divisez la suggestion en morceaux (ici, nous utilisons des mots)
              const suggestionWords = suggestionContent.split(" ");
              await Promise.all(
                suggestionWords.map((word, index) => {
                  return new Promise((resolve) => {
                    setTimeout(() => {
                      setData((prevValue) => `${prevValue}${word} `);
                      resolve();
                    }, index * 100);
                  });
                })
              );
            } catch (e) {
              console.error("Failed to parse JSON:", e);
              // Handle errors of parsing JSON
            } finally {
              handleIsWriting(false);
            }
          } else {
            const jsonMatch = [
              ...decodedChunk.matchAll(
                /data: ({.*?})(?=\s*event: data|\s*$)/gs
              ),
            ];

            jsonMatch.forEach((match) => {
              // console.log(match)
              const jsonStr = match[1].trim();

              try {
                const jsonData = JSON.parse(jsonStr);
                // console.log(jsonData)

                if ("content" in jsonData) {
                  let filteredText = jsonData.content.replace(/[*#]/g, "");
                  setData((prevValue) => `${prevValue}${filteredText}`);
                  // Continue reading the next chunk
                }
              } catch (e) {
                console.error("Failed to parse JSON:", e);
              }
            });
          }
        }
        handleIsWriting(loader);
        setStreamingComplete(true);
      } catch (error) {
        setLoading(false);
        // Handle errors during streaming
      }
    };
    if (!message.isUser && typeof message.text === "string") {
      setData(message.text);
    } else if (!message.isUser) {
      fetchData(message.text);
    }
    setLoading(loader);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, message.text]);

  useEffect(() => {
    if (!loading) {
      onDataFromChild(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="text-left mx-auto w-11/12">
      <div
        className={`mx-2 my-2 ${
          isLastMessage ? "" : "border-b-2"
        } border-[#D0D3DB] relative w-full`}
      >
        {message.isUser ? (
          <img
            src="https://cbimagesicons.blob.core.windows.net/chat-icons/user-wafina.png"
            alt="Utilisateur"
            className="mx-1 my-2 absolute top-0 -left-5"
            style={{ width: "30px", height: "30px" }}
          />
        ) : (
          <img
            src="https://cbimagesicons.blob.core.windows.net/chat-icons/wafina_icon.png"
            alt=""
            className="mx-1 my-2 absolute top-0 -left-5"
            style={{ width: "30px", height: "30px" }}
          />
        )}
        <div className="flex-col py-2 pl-6 mb-5 w-full">
          <div className="flex w-full">
            <span className="text-base font-bold block align-middle whitespace-nowrap">
              {message.isUser
                ? translations[selectLanguage].user
                : translations[selectLanguage].assistant}
            </span>
            {message.isUser && isLastMessage
              ? ""
              : isLastMessage &&
                streamingComplete && (
                  <TextToSpeechButton
                    textToSpeak={data}
                    isLastMessage
                    audioOn={audioOn}
                    selectLanguage={selectLanguage}
                  />
                )}
          </div>
          <span
            className="text-justify max-w-prose text-sm sm:text-base font-medium "
            style={{ whiteSpace: "pre-wrap" }}
          >
            {message.isUser ? message.text : data}
          </span>
        </div>
      </div>

      {/* <Tooltip
        id="my-tooltip"
        style={{
          backgroundColor: "#01795D",
          color: "#fff",
          borderRadius: "5px",
        }}
      /> */}
    </div>
  );
};

export default ChatMessage;
