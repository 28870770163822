export const translations = {
  en: {
    user: "You",
    assistant: "Wafina Assistant",
    agree: "I Agree",
    disagree: "I Disagree",
    agreed: "You have agreed.",
    disagreed: "You have not agreed.",
    welcome: "Hello, I am the AI assistant of Wafina!",
    askQuestions:
      "I am here to answer all your questions about Wafina and to assist you in navigating our site.",
    discover: "Ask me your questions...",
    search: "Searching in my sources",
  },
  de: {
    user: "Sie",
    assistant: "Wafina Assistent",
    agree: "Ich stimme zu",
    disagree: "Ich stimme nicht zu",
    agreed: "Sie haben zugestimmt.",
    disagreed: "Sie haben nicht zugestimmt.",
    welcome: "Hallo, ich bin der KI-Assistent von Wafina!",
    askQuestions:
      "Ich bin hier, um alle Ihre Fragen zu Wafina zu beantworten und Ihnen bei der Navigation auf unserer Website zu helfen.",
    discover: "Stellen Sie mir Ihre Fragen...",
    search: "Ich suche in meinen Quellen",
  },
  nl: {
    user: "U",
    assistant: "Wafina Assistent",
    agree: "Ik ga akkoord",
    disagree: "Ik ga niet akkoord",
    agreed: "U bent akkoord gegaan.",
    disagreed: "U bent niet akkoord gegaan.",
    welcome: "Hallo, ik ben de AI-assistent van Wafina!",
    askQuestions:
      "Ik ben hier om al uw vragen over Wafina te beantwoorden en om u te helpen bij het navigeren op onze site.",
    discover: "Stel mij uw vragen...",
    search: "Aan het zoeken in mijn bronnen",
  },
  fr: {
    user: "Vous",
    assistant: "Assistant de Wafina",
    agree: "Je suis d'accord",
    disagree: "Je ne suis pas d'accord",
    agreed: "Vous avez accepté.",
    disagreed: "Vous n'avez pas accepté.",
    welcome: "Bonjour, je suis l'assistant IA de Wafina !",
    askQuestions:
      "Je suis là pour répondre à toutes vos questions à propos de Wafina et pour vous assister dans votre navigation sur notre site.",
    discover: "Posez-moi vos questions ...",
    search: "Je cherche dans mes sources",
  },
};
