import React from "react";

const ChatSuggestion = ({ suggestion, onClick }) => {
  return (
    <div className="sm:w-1/2 pr-1 py-1">
      {" "}
      {/* Ajusté à sm au lieu de md pour changer à un breakpoint inférieur */}
      <button
        onClick={() => onClick(suggestion)}
        className="bg-[#D7DDFF] text-black text-xs rounded-lg w-full h-9 px-2 py-0.5 mr-1 border-[1px] border-[#9EB0FF] hover:bg-[#C9D2FF] text-left font-semibold block leading-tight sm:break-words break-words sm:whitespace-normal whitespace-nowrap"
      >
        {suggestion}
      </button>
    </div>
  );
};

const ChatSuggestions = ({ suggestions, onSuggestionClick }) => {
  return (
    <div className="mx-2 sm:mx-8 lg:mx-24 text-left mt-1 text-sm text-black">
      {/* <span>Suggestions :</span>
      <div className='flex sm:flex-wrap w-full overflow-x-auto no-scrollbar '>
        {Object.values(suggestions).map((suggestion, index) => (
          <ChatSuggestion
            key={index}
            suggestion={suggestion}
            onClick={onSuggestionClick}
          />
        ))}
      </div> */}
    </div>
  );
};

export default ChatSuggestions;
